import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Animated,
} from "react-native";
class Task extends Component {
  componentDidMount() {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 1200,
    }).start();
  }
  state = {
    opacity: new Animated.Value(0),
  };
  fadeout() {
    Animated.timing(this.state.opacity, {
      toValue: 0,
      duration: 800,
    }).start(({ finished }) => {
      Animated.timing(this.state.opacity, {
        toValue: 1,
        duration: 0,
      }).start();
      this.props.deletetask(this.props.count);
    });
  }
  render() {
    return (
      <Animated.View
        style={{
          opacity: this.state.opacity,
          backgroundColor: "#fff",
          padding: 15,
          borderRadius: 10,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <View style={styles.itemLeft}>
          <View style={styles.itemsquare}></View>
          <Text style={styles.itemText}>{this.props.text}</Text>
        </View>
        <TouchableOpacity onPress={() => this.fadeout()}>
          <View style={styles.circular}></View>
        </TouchableOpacity>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  item: {
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  itemLeft: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  itemsquare: {
    height: 24,
    width: 24,
    backgroundColor: "#55bcf6",
    opacity: 0.4,
    borderRadius: 5,
    marginRight: 15,
  },
  itemText: {
    maxWidth: "80%",
  },
  circular: {
    width: 12,
    height: 12,
    borderColor: "red",
    borderWidth: 2,
    borderRadius: 5,
  },
});
export default Task;
