import React, { Component } from "react";
import Login from "./widgets/login";
import Todolist from "./widgets/todolist";
import { View, Text } from "react-native";
import Carrousel from "./widgets/swipe/Carrousel";
import Account from "./widgets/account";
import NotificationList from "./widgets/notification/notificationlist";
class Main extends Component {
  state = {
    useremail: "",
    showloginpage: true,
    showtodopage: false,
    showAcccount: false,
    shownNotification: false,
    tasks: [],
    userdata: {},
    data: [
      {
        id: 0,
        title: "Task List",
        type: "product",
        img: require("./assets/todolist.png"),
        description: "Choosing the Best Gemstone for Your Necklace and Jewelry",
      },
      {
        id: 1,
        type: "product",
        title: "My account",
        img: require("./assets/account.png"),
        description: "Choosing the Best Gemstone for Your Necklace and Jewelry",
      },
      {
        id: 2,
        type: "product",
        img: require("./assets/notification.png"),
        title: "Notification List",
        description:
          "Don’t See One You Like? Choosing the Best Gemstone for Your Necklace and Jewelry",
      },
    ],
  };
  componentDidMount() {
    // this.showNotificationList();
  }
  loginsuccess = (email, tasks, userdata) => {
    this.setState({ useremail: email });
    this.setState({ showloginpage: false });
    this.setState({ showtodopage: true });
    this.state.tasks.push(...tasks);
    this.setState({ userdata: userdata });
  };
  showAcccountPage = () => {
    this.setState({ showAcccount: true });
    this.setState({ showtodopage: false });
  };
  signout = () => {
    this.setState({ showloginpage: true });
    this.setState({ showAcccount: false });
    this.setState({ showtodopage: false });
  };
  showTodoPage = () => {
    this.setState({ showAcccount: false });
    this.setState({ showtodopage: true });
  };
  showTodoPagewithJson = (json) => {
    this.setState({ showAcccount: false });
    this.setState({ showtodopage: true });
    this.setState({ userdata: json });
  };
  showNotificationList = () => {
    this.setState({ showloginpage: false, shownNotification: true });
    this.setState({ showAcccount: false });
    this.setState({ showtodopage: false });
  };
  hidenotification = () => {
    this.setState({ showtodopage: true, shownNotification: false });
  };
  render() {
    return (
      <View style={{ height: "100%", width: "100%" }}>
        {this.state.showloginpage && <Login loginsuccess={this.loginsuccess} />}
        {this.state.showtodopage && (
          <View style={{ height: "100%", width: "100%" }}>
            <Carrousel
              show={true}
              data={this.state.data}
              onScrollOutBoundLeft={() => {}}
              signout={this.signout}
              email={this.state.useremail}
              tasks={this.state.tasks}
              showaccount={this.showAcccountPage}
              shownotice={this.showNotificationList}
              showtodopage={this.showTodoPage}
              userdata={this.state.userdata}
              showTodoPagewithJson={this.showTodoPagewithJson}
            />
            {/* <Todolist
              signout={this.signout}
              email={this.state.useremail}
              tasks={this.state.tasks}
              showaccount={this.showAcccountPage}
              shownotice={this.showNotificationList}
            /> */}
          </View>
        )}
        {this.state.showAcccount && (
          <Account
            email={this.state.useremail}
            showtodopage={this.showTodoPage}
            signout={this.signout}
            userdata={this.state.userdata}
            showTodoPagewithJson={this.showTodoPagewithJson}
          />
        )}
        {this.state.shownNotification && (
          <NotificationList hidenotice={this.hidenotification} />
        )}
      </View>
    );
  }
}
export default Main;
