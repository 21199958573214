import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

class NoticeItem extends Component {
  render() {
    var date = new Date(this.props.time).toString();
    return (
      <View
        style={{
          backgroundColor: "#95f5be",
          padding: 15,
          borderRadius: 10,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <View style={styles.itemLeft}>
          <View style={styles.itemsquare}>
            <Text>{date}</Text>
          </View>
          <View style={styles.itemText}>
            <Text style={styles.title}>{this.props.title}</Text>
            <Text>{this.props.text}</Text>
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  item: {
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  itemLeft: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  itemsquare: {
    backgroundColor: "#55bcf6",
    opacity: 0.4,
    borderRadius: 5,
    marginRight: 15,
  },
  itemText: {
    maxWidth: "80%",
  },
  circular: {
    width: 12,
    height: 12,
    borderColor: "red",
    borderWidth: 2,
    borderRadius: 5,
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
  },
});
export default NoticeItem;
