import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  TextInput,
  ScrollView,
} from "react-native";
import Task from "./task";
class Todolist extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.forceUpdate();
    }, 1);
  }
  state = {
    task: "",
    tasklist: this.props.tasks,
    showmenuitem: false,
  };
  setTask(text) {
    this.state.task = text;
    this.state.tasklist.length;
  }
  showhidemenu() {
    this.setState({
      showmenuitem: !this.state.showmenuitem,
    });
  }
  // deletetask(count, parent) {
  //   parent.state.tasklist.splice(count, 1);
  //   console.log(parent);
  // }
  handletask() {
    if (this.state.task == "") {
      alert("Please insert a task");
    } else {
      this.state.tasklist.push(this.state.task);

      console.log(this.state.tasklist);
      this.textInput.clear();
      this.forceUpdate();
      fetch(
        "https://dev6.rentalmobilserpong.com/login/addtask?user=" +
          this.props.email +
          "&&task=" +
          this.state.task
      );
      this.state.task = "";
    }
  }
  render() {
    const deletetask = (count) => {
      console.log(count);
      fetch(
        "https://dev6.rentalmobilserpong.com/login/deletetask?user=" +
          this.props.email +
          "&&count=" +
          count
      );
      this.state.tasklist.splice(count, 1);
      this.setState({
        tasklist: this.state.tasklist,
      });
    };
    return (
      <View>
        <View style={styles.menu}>
          <TouchableOpacity onPress={() => this.showhidemenu()}>
            <View style={styles.menuButton}>
              <Text style={styles.addText}>Menu</Text>
            </View>
          </TouchableOpacity>
        </View>
        {/* Menu Item */}
        <View style={styles.menu2}>
          {this.state.showmenuitem && (
            <View>
              <TouchableOpacity onPress={() => this.props.showaccount()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>My Account Page</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.shownotice()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Notification List</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.signout()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Sign Out</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
        <ScrollView>
          <View style={styles.taskwrapper}>
            <Text style={styles.sectionTitle}>Today's task</Text>
            <View style={styles.items}></View>
            {this.state.tasklist.map((item, index) => {
              return (
                <Task
                  key={index}
                  text={item}
                  deletetask={deletetask}
                  count={index}
                />
              );
            })}
          </View>
          <KeyboardAvoidingView
            behavior="height"
            style={styles.writeTaskWrapper}
          >
            <TextInput
              ref={(input) => {
                this.textInput = input;
              }}
              style={styles.input}
              placeholder={"Write a task"}
              onChangeText={(text) => this.setTask(text)}
            />
            <TouchableOpacity onPress={() => this.handletask()}>
              <View style={styles.addWrapper}>
                <Text style={styles.addText}>+</Text>
              </View>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  taskwrapper: {
    paddingHorizontal: 20,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "bold",
  },
  items: {},
  writeTaskWrapper: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  input: {
    paddingVertical: 15,
    width: 250,
    paddingHorizontal: 15,
    backgroundColor: "#fff",
    borderRadius: 60,
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
  addWrapper: {
    width: 60,
    height: 60,
    backgroundColor: "#fff",
    borderRadius: 60,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
  menu: {
    zIndex: 12,
  },
  menu2: { zIndex: 12 },
  addText: {},
  menuButton: {
    position: "relative",
    width: 60,
    height: 60,
    backgroundColor: "#fff",
    borderRadius: 60,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
  menuItem: {
    backgroundColor: "#fff",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
});
export default Todolist;
