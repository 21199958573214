import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import NoticeItem from "./notificationItem";
class NotificationList extends Component {
  state = {
    notices: [],
  };
  showhidemenu() {
    this.setState({
      showmenuitem: !this.state.showmenuitem,
    });
  }
  componentDidMount() {
    const fetchData = async () => {
      const resp = await fetch(
        "https://dev6.rentalmobilserpong.com/login/viewnotice"
      );
      const data = await resp.json();
      if (data.result == "yes") {
        this.setState({
          notices: data.notice,
        });
      }
    };
    fetchData();
  }
  render() {
    return (
      <View>
        <View style={styles.menu}>
          <TouchableOpacity onPress={() => this.showhidemenu()}>
            <View style={styles.menuButton}>
              <Text style={styles.addText}>Menu</Text>
            </View>
          </TouchableOpacity>
        </View>
        {/* Menu Item */}
        <View style={styles.menu2}>
          {this.state.showmenuitem && (
            <View>
              <TouchableOpacity onPress={() => this.props.showtodopage()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Todo List</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.showaccount()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Account page</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.signout()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Sign Out</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
        {this.state.notices.map((item, index) => {
          return (
            <NoticeItem
              key={index}
              title={item.title}
              text={item.text}
              time={item.time}
            />
          );
        })}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  label: {
    alignItems: "center",
    marginBottom: "2%",
    width: "80%",
  },
  labelText: {
    fontSize: 30,
    fontWeight: "bold",
  },
  menu: {
    zIndex: 12,
  },
  menu2: { zIndex: 12 },
  addText: {},
  menuButton: {
    position: "relative",
    width: 60,
    height: 60,
    backgroundColor: "#fff",
    borderRadius: 60,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
  menuItem: {
    backgroundColor: "#fff",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
});
export default NotificationList;
