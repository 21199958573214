import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
  Button,
} from "react-native";
class Account extends Component {
  state = {
    showmenuitem: false,
    firstname: this.props.userdata.firstname,
    lastname: this.props.userdata.lastname,
    contact: this.props.userdata.contact,
    address: this.props.userdata.address,
    note: this.props.userdata.note,
  };
  showhidemenu() {
    this.setState({
      showmenuitem: !this.state.showmenuitem,
    });
  }
  updatedata() {
    fetch(
      "https://dev6.rentalmobilserpong.com/login/update_data?user=" +
        this.props.email +
        "&&first_name=" +
        this.replacetextSpace(this.state.firstname) +
        "&&last_name=" +
        this.replacetextSpace(this.state.lastname) +
        "&&contact=" +
        this.replacetextSpace(this.state.contact) +
        "&&note=" +
        this.replacetextSpace(this.state.note) +
        "&&address=" +
        this.replacetextSpace(this.state.address)
    );

    alert("Profile updated");
    this.props.showTodoPagewithJson({
      address: this.state.address,
      contact: this.state.contact,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      note: this.state.note,
    });
  }

  replacetextSpace = (text) => {
    var testdata = text.split(" ");
    var output = "";
    for (var i = 0; i < testdata.length - 1; i++) {
      output = output + testdata[i] + ".@@.";
    }
    console.log(text);
    if (text == "") {
      output = ".@epmty@.";
    } else if (testdata.length == 1) {
      output = text;
    }
    console.log(output);
    return output;
  };
  handlefirstname = (text) => {
    this.setState({ firstname: text });
  };
  handlelastname = (text) => {
    this.setState({ lastname: text });
  };
  handlecontact = (text) => {
    this.setState({ contact: text });
  };
  handleaddress = (text) => {
    this.setState({ address: text });
  };
  handlenote = (text) => {
    this.setState({ note: text });
  };
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.menu}>
          <TouchableOpacity onPress={() => this.showhidemenu()}>
            <View style={styles.menuButton}>
              <Text style={styles.addText}>Menu</Text>
            </View>
          </TouchableOpacity>
        </View>
        {/* Menu Item */}
        <View style={styles.menu2}>
          {this.state.showmenuitem && (
            <View>
              <TouchableOpacity onPress={() => this.props.showtodopage()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Todo List</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.shownotice()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Notification List</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.signout()}>
                <View style={styles.menuItem}>
                  <Text style={styles.addText}>Sign Out</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
        <Text>{this.props.email}</Text>
        <View style={styles.row}>
          <Text style={styles.text}>First Name</Text>
          <TextInput
            style={styles.input}
            value={this.state.firstname}
            onChangeText={this.handlefirstname}
          ></TextInput>
          <Text style={styles.text}>Last Name</Text>
          <TextInput
            style={styles.input}
            value={this.state.lastname}
            onChangeText={this.handlelastname}
          ></TextInput>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>Contact Number</Text>
          <TextInput
            style={styles.input}
            value={this.state.contact}
            onChangeText={this.handlecontact}
          ></TextInput>
          <Text style={styles.text}>Notes</Text>
          <TextInput
            style={styles.input}
            value={this.state.note}
            onChangeText={this.handlenote}
          ></TextInput>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>Address</Text>
          <TextInput
            style={styles.input}
            value={this.state.address}
            onChangeText={this.handleaddress}
          ></TextInput>
          <Text style={styles.text}>Salary</Text>
          <Text style={styles.input}>{this.props.userdata.salary} Rp</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button onPress={() => this.updatedata()} title="Save" />
          <Button onPress={() => this.props.showtodopage()} title="Cancel" />
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: { alignItems: "center", paddingTop: 20 },
  row: {
    flexDirection: "row",
    borderWidth: 8,
    borderRadius: 60,
    margin: 10,
    borderColor: "#12ff03",
  },
  text: {
    marginRight: 10,
  },
  input: {
    width: "20%",
    color: "blue",
  },
  menu: {
    zIndex: 12,
  },
  menu2: { zIndex: 12 },
  addText: {},
  menuButton: {
    position: "relative",
    width: 60,
    height: 60,
    backgroundColor: "#fff",
    borderRadius: 60,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
  menuItem: {
    backgroundColor: "#fff",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#c0c0c0",
    borderWidth: 1,
  },
});
export default Account;
