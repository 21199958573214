import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  StatusBar,
  Image,
} from "react-native";

class Login extends Component {
  state = {
    email: "",
    password: "",
    status: "",
    popup: false,
    hidelogin: false,
    Otp: "",
    type: "",
    userOtp: "",
    setpassword: false,
    processing: false,
  };
  handleEmail = (text) => {
    this.setState({ email: text });
  };
  handlePassword = (text) => {
    this.setState({ password: text });
  };
  handleOtp = (text) => {
    this.setState({ userOtp: text });
  };
  login = () => {
    const fetchData = async () => {
      const resp = await fetch(
        "https://dev6.rentalmobilserpong.com/login/loginWithUrl?user=" +
          this.state.email +
          "&&password=" +
          this.state.password
      );
      const data = await resp.json();
      console.log(data);
      if (data.result == "Yes") {
        this.props.loginsuccess(this.state.email, data.tasks, data.userinfo);
      } else {
        this.state.status = "User name or password not matched";
      }
      this.setState({ processing: false });
      this.forceUpdate();
    };
    if (!this.state.email.includes("@")) {
      this.state.status = "Please enter a valid email";
      this.forceUpdate();
    } else {
      try {
        this.setState({ processing: true });
        fetchData();
      } catch (e) {
        console.log(e);
      }
    }
  };
  createAccount = () => {
    const fetchData = async () => {
      const resp = await fetch(
        "https://dev6.rentalmobilserpong.com/login/send_mail?user=" +
          this.state.email
      );
      const data = await resp.json();
      if (data.result == "Yes") {
        this.state.status = "Account already existed";
      } else if (data.result == "No") {
        this.state.status = "Please try again";
      } else {
        this.state.Otp = data.result;
        this.state.type = "create";
        this.setState({ popup: !this.state.popup });
        this.setState({ hidelogin: !this.state.hidelogin });
        this.setState({ status: "Insert Otp" });
      }
      this.setState({ processing: false });
      this.forceUpdate();
    };
    if (!this.state.email.includes("@")) {
      this.state.status = "Please enter a valid email";
      this.forceUpdate();
    } else {
      this.setState({ processing: true });
      fetchData();
    }
  };
  showpopup = () => {
    console.log("yes");
    this.setState({ popup: !this.state.popup });
    this.setState({ status: "Insert Otp" });
  };
  hidepopup = () => {
    this.setState({ status: "" });
    this.setState({ popup: !this.state.popup });
    this.setState({ hidelogin: !this.state.hidelogin });
  };
  forgotpassword = () => {
    const fetchData = async () => {
      const resp = await fetch(
        "https://dev6.rentalmobilserpong.com/login/forgotpass?user=" +
          this.state.email
      );
      const data = await resp.json();
      if (data.result == "Not") {
        this.state.status = "Account not existed";
      } else if (data.result == "No") {
        this.state.status = "Please try again";
      } else {
        this.state.Otp = data.result;
        this.state.type = "forgot";
        this.setState({ popup: !this.state.popup });
        this.setState({ hidelogin: !this.state.hidelogin });
        this.setState({ status: "Insert Otp" });
      }
      this.setState({ processing: false });
      this.forceUpdate();
    };
    if (!this.state.email.includes("@")) {
      this.state.status = "Please enter a valid email";
      this.forceUpdate();
    } else {
      this.setState({ processing: true });
      fetchData();
    }
  };

  submit = () => {
    if (this.state.Otp == this.state.userOtp) {
      this.setState({ password: "" });
      this.setState({ status: "" });
      this.setState({ setpassword: !this.state.setpassword });
      this.setState({ popup: !this.state.popup });
    } else {
      this.setState({ status: "Invalid Otp" });
      this.forceUpdate();
    }
  };
  create_or_update_user = () => {
    const fetchData = async () => {
      const resp = await fetch(
        "https://dev6.rentalmobilserpong.com/login/create_update_user?user=" +
          this.state.email +
          "&&password=" +
          this.state.password +
          "&&type=" +
          this.state.type
      );
      const data = await resp.json();
      if (data.result == "Yes") {
        if (this.state.type == "forgot") {
          this.state.status = "Password changed";
        } else {
          this.state.status = "Account created";
        }

        this.setState({ hidelogin: !this.state.hidelogin });
        this.setState({ setpassword: !this.state.setpassword });
      }
      this.setState({ processing: false });
      this.forceUpdate();
    };
    if (this.state.password.length == 0) {
      this.state.status = "Please enter password";
      this.forceUpdate();
    } else {
      try {
        this.setState({ processing: true });
        fetchData();
      } catch (e) {
        console.log(e);
      }
    }
  };
  render() {
    return (
      <View style={{ alignItems: "center" }}>
        <Image style={styles.image} source={require("../assets/logo.png")} />
        <StatusBar style="auto" />
        {!this.state.processing && (
          <View style={{ width: "100%", alignItems: "center" }}>
            <View>
              <Text>{this.state.status}</Text>
            </View>
            {/* Login page */}
            {!this.state.hidelogin && (
              <View style={styles.inputView}>
                <TextInput
                  style={styles.TextInput}
                  placeholder="Email."
                  placeholderTextColor="#003f5c"
                  onChangeText={this.handleEmail}
                />
              </View>
            )}
            {!this.state.hidelogin && (
              <View style={styles.inputView}>
                <TextInput
                  style={styles.TextInput}
                  placeholder="Password."
                  placeholderTextColor="#003f5c"
                  secureTextEntry={true}
                  onChangeText={this.handlePassword}
                />
              </View>
            )}
            {!this.state.hidelogin && (
              <TouchableOpacity onPress={() => this.forgotpassword()}>
                <Text style={styles.forgot_button}>Forgot Password?</Text>
              </TouchableOpacity>
            )}

            {!this.state.hidelogin && (
              <TouchableOpacity
                style={styles.loginBtn}
                onPress={() => this.login()}
              >
                <Text style={styles.loginText}>LOGIN</Text>
              </TouchableOpacity>
            )}
            {!this.state.hidelogin && (
              <TouchableOpacity
                style={styles.createBtn}
                onPress={() => this.createAccount()}
              >
                <Text style={styles.loginText}>Create Account</Text>
              </TouchableOpacity>
            )}

            {/* Popup Page */}
            {this.state.popup && (
              <View style={styles.inputView}>
                <TextInput
                  style={styles.TextInput}
                  placeholder="OTP"
                  placeholderTextColor="#003f5c"
                  secureTextEntry={true}
                  onChangeText={this.handleOtp}
                />
              </View>
            )}
            {this.state.popup && (
              <TouchableOpacity
                style={styles.loginBtn}
                onPress={() => this.submit()}
              >
                <Text style={styles.loginText}>Submit</Text>
              </TouchableOpacity>
            )}
            {this.state.popup && (
              <TouchableOpacity
                style={styles.loginBtn}
                onPress={() => this.hidepopup()}
              >
                <Text style={styles.loginText}>Cancel</Text>
              </TouchableOpacity>
            )}
            {/* Set password */}
            {this.state.setpassword && (
              <View style={styles.inputView}>
                <TextInput
                  style={styles.TextInput}
                  placeholder="Password."
                  placeholderTextColor="#003f5c"
                  secureTextEntry={true}
                  onChangeText={this.handlePassword}
                />
              </View>
            )}

            {this.state.setpassword && (
              <TouchableOpacity
                style={styles.loginBtn}
                onPress={() => this.create_or_update_user()}
              >
                <Text style={styles.loginText}>Set password</Text>
              </TouchableOpacity>
            )}
          </View>
        )}
        {this.state.processing && (
          <View style={{ width: "100%", alignItems: "center" }}>
            <Text style={styles.processingText}>Checking</Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    marginBottom: 40,
    height: 100,
    width: 150,
  },
  processingText: {
    padding: 10,
    borderRadius: 60,
    backgroundColor: "purple",
  },
  inputView: {
    backgroundColor: "#FFC0CB",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,

    alignItems: "center",
  },

  TextInput: {
    height: 50,
    flex: 1,
    padding: 10,
    marginLeft: 20,
  },

  forgot_button: {
    height: 30,
    marginBottom: 30,
  },

  loginBtn: {
    width: "80%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    backgroundColor: "#FF1493",
  },
  createBtn: {
    width: "80%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    backgroundColor: "#5414ff",
  },
});
export default Login;
